import { createSlice } from "@reduxjs/toolkit";
import { post } from "./../services/service-api";

const tag = "slc-";

const token = localStorage.getItem(tag + "token");
const tokenFileManager = localStorage.getItem(tag + "token_file_manager");
const user = localStorage.getItem(tag + "user");

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    user: user ? JSON.parse(user) : null,
    token: token,
    tokenFileManager: tokenFileManager,
    error: null,
    errorDetail: null,
  },
  reducers: {
    loginBegin(state) {
      state.loading = true;
      state.user = null;
      state.token = null;
      state.tokenFileManager = null;
      state.error = null;
      state.errorDetail = null;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.tokenFileManager = action.payload.tokenFileManager;
      state.error = null;
      state.errorDetail = null;
    },
    loginFailed(state, action) {
      state.loading = false;
      state.user = null;
      state.token = null;
      state.tokenFileManager = null;
      state.error = action.payload.error;
      state.errorDetail = action.payload.errorDetail;
    },
    logout(state) {
      state.user = null;
      state.token = null;
      state.tokenFileManager = null;
    },
  },
});

const authActions = authSlice.actions;

const logoutLocalStorage = () => {
  localStorage.setItem(tag + "user", null);
  localStorage.setItem(tag + "token", null);
  localStorage.setItem(tag + "token_file_manager", null);
};

const logout = () => {
  return (dispatch) => {
    dispatch(authActions.logout());
    logoutLocalStorage();
  };
};

const login = ({ username, password }) => {
  return async (dispatch) => {
    dispatch(authActions.loginBegin());
    try {
      const response = await post({
        url: "/api/login",
        body: { username: username, password: password },
      });

      console.log(response);

      const { access_token, /*file_manager_access_token,*/ message, data } =
        response.data;
      dispatch(
        authActions.loginSuccess({
          user: data,
          token: access_token,
          // tokenFileManager: file_manager_access_token,
        })
      );

      localStorage.setItem(tag + "user", JSON.stringify(data));
      localStorage.setItem(tag + "token", access_token);
      // localStorage.setItem(
      //   tag + "token_file_manager",
      //   file_manager_access_token
      // );
      return response.data;
    } catch (error) {
      const errorResponse = error.response;
      const { data, message } = errorResponse.data;
      dispatch(authActions.loginFailed({ error: message }));
      return null;
    }
  };
};

export { authActions, login, logout, logoutLocalStorage };
export default authSlice;
