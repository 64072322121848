// import logo from "./logo.svg";
import "./App.css";

import idLocale from "./assets/locale/id.json";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { addLocale } from "primereact/api";

import ErrorPage from "./pages/error/ErrorPage";
import LoginPage from "./pages/login/LoginPage";

// import LayoutDashboardPage from "./pages/dashboard/LayoutDashboardPage";

// //import protector
// import ProtectedRoute from "./pages/protected/ProtectedRoute";
// import HomePage from "./pages/dashboard/default/HomePage";
// import WarehouseMasterPage from "./pages/dashboard/master/warehouse-master/WarehouseMasterPage";
// import ProductCategoryMasterPage from "./pages/dashboard/master/product-category-master/ProductCategoryMasterPage";

function App() {
  addLocale("id", idLocale.id);

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route index path="/" element={<LoginPage />}></Route> */}
        <Route index path="/login" element={<LoginPage />}></Route>

        {/* <Route
          // index
          path="/"
          element={
            <ProtectedRoute>
              <LayoutDashboardPage />
            </ProtectedRoute>
          }
        >
          <Route index element={<HomePage />}></Route>

          <Route path="master">
            <Route path="warehouse" element={<WarehouseMasterPage />} />
            <Route
              path="product-category"
              element={<ProductCategoryMasterPage />}
            />
          </Route>
        </Route>
 */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
