import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    sideMenuIsVisible: true,
    floatingProfileIsVisible: false,
    layoutDashboardTitle: "",
  },
  reducers: {
    toggleSideMenuVisibility(state) {
      state.sideMenuIsVisible = !state.sideMenuIsVisible;
    },
    toggleFloatingProfileVisibility(state) {
      state.floatingProfileIsVisible = !state.floatingProfileIsVisible;
    },
    setLayoutDashboardTitle(state, action) {
      state.layoutDashboardTitle = action.payload.title;
    },
  },
});

const uiActions = uiSlice.actions;

const toggleSideMenuVisibility = () => {
  return (dispatch) => {
    dispatch(uiActions.toggleSideMenuVisibility());
  };
};

const toggleFloatingProfileVisibility = () => {
  return (dispatch) => {
    dispatch(uiActions.toggleFloatingProfileVisibility());
  };
};

const setLayoutDashboardTitle = ({ title }) => {
  return (dispatch) => {
    dispatch(uiActions.setLayoutDashboardTitle({ title }));
  };
};

export {
  uiActions,
  toggleSideMenuVisibility,
  toggleFloatingProfileVisibility,
  setLayoutDashboardTitle,
};

export default uiSlice;
