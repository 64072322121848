const onErrorToast = ({ toast, message, data }) => {
  toast.current.show({
    severity: "error",
    summary: "Error",
    detail: message,
    life: 3000,
  });
};

export default onErrorToast;
