import classes from "./LoginPage.module.css";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "./../../store/auth-slice";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import onSuccessToast from "../../utils/OnSuccessToast";
import onErrorToast from "../../utils/OnErrorToast";
import { useNavigate } from "react-router-dom";
import { APP_NAME, MY_URL } from "../../configs/constants";
import { Divider } from "primereact/divider";
import { useSearchParams } from "react-router-dom";

const title = APP_NAME;
const logoUrl = `${MY_URL}/logo256.png`;
const backgroundSmallUrl = `${MY_URL}/small-background.jpg`;
const backgroundUrl = `${MY_URL}/background.png`;

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const toast = useRef(null);

  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const error = useSelector((state) => state.auth.error);
  const [errorDetail, setErrorDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const username = inputUsername.trim();
    const password = inputPassword.trim();

    const webAppLogin = searchParams.get("web-app-login");

    const responseData = await dispatch(
      login({
        username: username,
        password: password,
        webAppLogin: webAppLogin || webAppLogin === null ? false : true,
      })
    );
    if (responseData) {
      setLoading(false);
      onSuccessToast({ toast: toast, message: "Login Success" });
      if (webAppLogin) {
        window.opener.postMessage({ slc_data: responseData }, "*");
        window.close();
      } else {
        navigate("/");
      }
    } else {
      onErrorToast({ toast: toast, message: "Login Failed" });
    }
    setLoading(false);
  };

  return (
    <div className={`${classes.wrapper} row-center-center w-100 `}>
      <Toast ref={toast} />

      <section
        className={`${classes.floatingContainer} ${classes.loginSection} py-5`}
      >
        <div className="col-center-center w-100 gap-2 pb-4">
          <img
            className={`${classes.logoImage}`}
            alt="logo"
            src={logoUrl}
          ></img>
          <span className={`${classes.title}`}>{title}</span>
        </div>

        <div className="col-center-center gap-2 pb-3">
          <span className={`${classes.subtitle2}`}>
            Sign in to your account
          </span>
        </div>
        <div className="col-center-center gap-2 mb-3">
          <div className="col-start-start gap-1 w-100 ">
            {/* <label className={`${classes.prompt}`} htmlFor="username">
                Username
              </label> */}
            <InputText
              className={`${classes.customInput}`}
              id="username"
              placeholder="Username"
              aria-describedby="username-help"
              onChange={(e) => setInputUsername(e.target.value)}
            />
            {errorDetail && errorDetail.username && (
              <span className={`${classes.errorLabel} `}>
                {errorDetail.username}
              </span>
            )}
          </div>
          <div className="col-start-start gap-1 w-100">
            {/* <label className={`${classes.prompt}`} htmlFor="password">
                Password
              </label> */}
            <span></span>
            <Password
              className={`${classes.customInput}`}
              id="password"
              placeholder="Password"
              aria-describedby="password-help"
              toggleMask
              onChange={(e) => setInputPassword(e.target.value)}
            />
            {errorDetail && errorDetail.password && (
              <span className={`${classes.errorLabel} `}>
                {errorDetail.password}
              </span>
            )}
          </div>
          {error && (
            <span className={`${classes.errorLabelSingle} `}>{error}</span>
          )}
        </div>
        <div className="col-center-center gap-2">
          <Button
            loading={loading}
            className={`${classes.loginButton} w-100`}
            style={{
              backgroundColor: "var(--color-sttc)",
              borderColor: "var(--color-sttc)",
            }}
            label="LOGIN"
            onClick={(e) => {
              e.preventDefault();
              handleLogin(e);
            }}
          />
        </div>
      </section>
    </div>
  );
};

export default LoginPage;
