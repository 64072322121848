const onSuccessToast = ({ toast, message, data }) => {
  toast.current.show({
    severity: "success",
    summary: "Success",
    detail: message,
    life: 3000,
  });
};

export default onSuccessToast;
